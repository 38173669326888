import React, {useState} from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

export const validatePhone = (value) => {
    // const regExp = /^\+[1-9]\d{1,14}$/;
    // return regExp.test(String(value).toLowerCase());
    return value;
};
export const validateEmail = (value) => {
    const regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regExp.test(String(value).toLowerCase());
};
export const validateName = (value) => {
    return /\S/.test(value);
};
const TextInput = ({
    placeholder = '',
    className = '',
    value = '',
    required = true,
    invalidMessagePath = 'errors.requiredField',
    onChange = () => {},
    forceValidation = false,
    type = 'text',
    dataTestId,
}) => {
    const { t } = useTranslation();
    const [touched, setTouched] = useState(false);
    const validator = React.useCallback((value) =>  {
        switch (type) {
            case 'tel':
                return validatePhone(value) ? '' : 'errors.invalidPhone';
            case 'email':
                return validateEmail(value) ? '' : 'errors.invalidEmail';
            case 'name':
                return validateName(value)? '' :'errors.invalidName';
            default:
                return ''
        };
    }, [type]);
    const isEmpty = (required && !value) && (touched || forceValidation);
    const isInvalid = validator(value) && (touched || forceValidation);
    const finalPLaceholder = required ? `${placeholder} *` : placeholder;
    const errorMsgPath = React.useMemo(() => validator(value), [validator, value]);

    return (
        <div className={`sm:w-1/2 px-3 mb-3 ${className}`}>
            <input
                type={type}
                placeholder={finalPLaceholder}
                className={`border-2 rounded px-3 py-1 w-full input focus:outline-none ${isInvalid || isEmpty ? 'border-red-500' :''} no-arrows`}
                value={value}
                onChange={onChange}
                onBlur={() => setTouched(true)}
                style={{}}
                data-testid={dataTestId}
            />
            {
                isEmpty ? ( 
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                        { t(invalidMessagePath) }
		            </span>
                ) : isInvalid ? (
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1" data-testid={`invalid-${type}`}>
                        { t(errorMsgPath) }
		            </span>
                ) : null
            }
        </div>
    )
};

export default TextInput;
