import * as React from "react";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";

// styles
import ReactFlagsSelect from "react-flags-select";

import fetchCountriesInfo, { DEFAULT_COUNTRY } from "../utils/fetchCountriesInfo";
const countriesI18n = require("i18n-iso-countries");
countriesI18n.registerLocale(require("i18n-iso-countries/langs/en.json"));
countriesI18n.registerLocale(require("i18n-iso-countries/langs/fr.json"));
const hasLanguageKey = (url, knownLanguages) => {
    const urlObject = new URL(url);
    const pathSegments = urlObject.pathname.split('/').filter(Boolean); // Split and remove empty segments

    return pathSegments.length > 0 && knownLanguages.includes(pathSegments[0]);
};


const FRENCH_SPEAKING_COUNTRIES = [
    "FR",
    "BE",
    "CH",
    "LU",
    "MC",
    "CA",
    "CD",
    "CG",
    "CI",
    "GA",
    "GN",
    "CM",
    "MG",
    "ML",
    "NE",
    "SN",
    "TD",
    "TG",
    "MU",
    "BF",
    "BI",
    "CF",
    "KM",
    "DJ",
    "GQ",
    "HT",
    "RW",
    "SC",
    "VU",
    "MR",
    "MA",
    "DZ",
    "TN"
];

let initialized = false;

const CountryInput = ({
    id = "",
    name = "",
    placeholder = "",
    className = "",
    value = "",
    required = true,
    onChange = () => { },
    forceValidation = false,
}) => {
    const { t } = useTranslation();
    let { language, changeLanguage, languages } = useI18next();

    const [countries, setCountries] = React.useState([DEFAULT_COUNTRY]);
    const [labels, setLabels] = React.useState();

    const [touched, setTouched] = React.useState(false);
    const isEmpty = required && !value && (touched || forceValidation);
    const handleChange = React.useCallback((phone) => {
        onChange({ target: { value: phone } });
    }, [onChange]);
    React.useEffect(() => {
        async function fetchData() {
            const { defaultCountry: country, supportedCountries } = await fetchCountriesInfo();
            setCountries(supportedCountries)
            if (country) {
                handleChange(country.code);
                // set the country default language
                if (!initialized) {
                    initialized = true;
                    const defaultCountryLanguage = FRENCH_SPEAKING_COUNTRIES.find((countryCode) => countryCode === country.code) ? "fr" : "en";
                    language = defaultCountryLanguage;
                    if (!hasLanguageKey(window.location.href, languages))
                        await changeLanguage(defaultCountryLanguage);
                }
            }

            let labels = {};
            const ret = supportedCountries?.map((country) => {
                labels[country.code] = countriesI18n.getName(country.code, language);
            });
            setLabels(labels);
        }
        fetchData();
    }, [])
    const onBlur = React.useCallback(() => setTouched(true), []);

    return (
        <div
            className={`sm:w-1/2 px-3 mb-3 ${className} ${isEmpty ? "error" : ""}`}
        >
            <ReactFlagsSelect
                selected={value}
                onSelect={handleChange}
                countries={countries?.map(country => country.code)}
                customLabels={labels}
                searchable
                onBlur={onBlur}
                searchPlaceholder={t("searchCountries")}
                fullWidth
            />
        </div>
    );
};

export default CountryInput;