import React, {useState} from 'react';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import TextInput from "../TextInput";
import PhoneInput from "../PhoneInput"
import TextArea from "../TextArea";
import Button from "../Button";
import axios from 'axios';
import { useToasts } from 'react-toast-notifications';
import config from "../../config";
import usePhoneNumberUtils from "../../hooks/usePhoneNumberUtils";

const ContactForm = () => {
    const { t } = useTranslation();
    const [formState, setFormState] = useState({
        fullName: '',
        companyName: '',
        email: '',
        subject: '',
        message: '',
        phone: '',
        forceValidation: false,
        submitting: false,
    });

    const { addToast } = useToasts();
    
    const [countryCode, setCountryCode] = React.useState("MA");
    const { validator: phoneValidator } = usePhoneNumberUtils({ country: countryCode });
    const onFieldChange = (fieldName, fieldValue) => setFormState({
        ...formState,
        [fieldName] : fieldValue,
    });

    const onFullNameChange = (event) => onFieldChange('fullName', event.target.value);

    const onPhoneChange = (event) => onFieldChange('phone', event.target.value);

    const onCompanyNameChange = (event) => onFieldChange('companyName', event.target.value);

    const onEmailChange = (event) => onFieldChange('email', event.target.value);

    const onSubjectChange = (event) => onFieldChange('subject', event.target.value);

    const onMessageChange = (event) => onFieldChange('message', event.target.value);

    const forceValidation = (value) => onFieldChange('forceValidation', value);

    const setSubmitting = (value) => onFieldChange('submitting', value);

    const onSubmit = () => {
        const {
            fullName,
            companyName,
            email,
            subject,
            message,
            submitting,
            phone,
        } = formState;

        if(submitting) return;

        const isFormValid = !!fullName && !!email && !!subject && !!message && !!phone;
        if(!isFormValid) {
            // force the validation to show the validation messages in the invalid fields
            forceValidation(true);
        }else {
            // call the backend
            setSubmitting(true);
            axios.post(`${config.GATSBY_API_URL}/contactus`, {
                fullName,
                phone,
                companyName,
                email,
                subject,
                message,
            })
                .then(function (response) {
                    setSubmitting(false);
                    //TODO open #contact_success
                    // window.location.href = window.location.origin+'#contact_success'
                    addToast(t('contact.successMsg'), { appearance: 'success'});
                })
                .catch(function (error) {
                    setSubmitting(false);
                    addToast(t('contact.errorMsg'), { appearance: 'error', autoDismiss: false });
                });
        }
    };
//

    return (
        <div className="contact-form">
            <div className="sm:flex sm:flex-wrap -mx-3">
                <TextInput
                    placeholder={t('name')}
                    required
                    value={formState.fullName}
                    onChange={onFullNameChange}
                    forceValidation={formState.forceValidation}
                />
                <TextInput
                    placeholder={t('phone')}
                    required
                    value={formState.phone}
                    onChange={onPhoneChange}
                    forceValidation={formState.forceValidation}
                    type='number'
                />
                <TextInput
                    placeholder={t('establishmentName')}
                    required
                    value={formState.companyName}
                    onChange={onCompanyNameChange}
                    forceValidation={formState.forceValidation}
                />
                <TextInput
                    placeholder={t('emailAddress')}
                    required
                    value={formState.email}
                    onChange={onEmailChange}
                    forceValidation={formState.forceValidation}
                />
                <TextInput
                    placeholder={t('subject')}
                    required
                    value={formState.subject}
                    onChange={onSubjectChange}
                    forceValidation={formState.forceValidation}
                />
                <TextArea
                    placeholder={t('yourMessage')}
                    required
                    value={formState.message}
                    onChange={onMessageChange}
                    forceValidation={formState.forceValidation}
                />
            </div>
            <div className="text-right mt-4 md:mt-12">
                <Button
                    type="submit"
                    className={`text-sm bg-primary text-white ${formState.submitting ? 'disabled:opacity-50' :''}`}
                    ty
                    size="organized"
                    onClick={onSubmit}
                    disabled={formState.submitting}
                >
                    <Trans i18nKey="send" />
                </Button>
            </div>
        </div>
    );
}

export default ContactForm;
