import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';

// components
import Button from '../components/Button';
//import Card from '../components/Card';
import ContactForm from '../components/layout/ContactForm';
import Layout from '../components/layout/Layout';
import DriverGraphic from '../svg/DriverGraphic';
import Accordion from '../components/Accordion';
import FreeTrialForm from '../components/FreeTrialForm';
import Pricing from '../components/Pricing';
import OurSolutions from '../components/OurSolutions';
import Features from '../components/Features';
import YoutubeVideo from '../components/YoutubeVideo';

// styles
import '../css/global.css';
import '../css/carousel.css';

export default () => {
  const [pricingPackage, setPricingPackage] = React.useState('default');
  const [country, setCountry] = React.useState('MA');
  const handleCountryChange = React.useCallback(
    (newCountry) => {
      if (country !== newCountry) setCountry(newCountry);
    },
    [country]
  );
  const handlePricingPackage = React.useCallback((value) => setPricingPackage(value), []);
  const { t } = useTranslation();
  const accordionContent = React.useMemo(() => t('faq.QR', { returnObjects: true }), [t]);
  return (
    <Layout>
      <section className='py-20 pt-24'>
        <div className='md:mx-24 mx-2 sm:mx-10 lg:flex'>
          <div className='text-center lg:text-left lg:w-1/2'>
            <h2 className='text-4xl lg:text-5xl font-bold leading-none'>
              <Trans i18nKey="mainValueProposition"/>
            </h2>
            <div className='lg:w-1/2 pt-6 lg:hidden block'>
              <DriverGraphic />
            </div>
            <p className='text-xl lg:text-2xl mt-6 font-light'>
              <Trans i18nKey="description"/>
            </p>
            {/*<p className='mt-4 text-gray-600'>
              <br />
              <span className='blueCtaText'>
                <Trans i18nKey="mainCallToAction"/>
              </span>
            </p>*/}
            <p className='mt-8 md:mt-12'>
              <AnchorLink className='px-04' href='#free-trial'>
                <Button className='lg:py-3 lg:px-8 py-2 px-4 bg-primary text-white'>
                  <Trans i18nKey='createAccount' />
                </Button>
              </AnchorLink>

              <AnchorLink className='pl-4' href='#contact'>
                <Button className='lg:py-3 lg:px-8 py-2 px-4 bg-primary text-white'>
                  <Trans i18nKey='contactUs' />
                </Button>
              </AnchorLink>
            </p>
            {
              country==="MA" ? <YoutubeVideo /> : null
            }
          </div>
          <div className='lg:w-1/2 lg:block hidden'>
            <DriverGraphic />
          </div>
        </div>
      </section>

      <section id='free-trial' className='py-20 px-3'>
        <div className=' md:mx-24 mx-2 sm:mx-10 text-center'>
          <h2 className='text-3xl lg:text-5xl font-semibold text-center w-full'>
            <Trans i18nKey='freeTrial.mainTitle' />
          </h2>
          {/* <div className="mt-8 text-xl font-light" dangerouslySetInnerHTML={{ __html: t("freeTrial.subtitle") }} /> */}
        </div>
        <div className='px-3 py-3 w-full flex-1 only-for-mobile' >
          <FreeTrialForm
          
            pricingPackageState={pricingPackage}
            handleCountryChange={handleCountryChange}
            device="mobile"
          />
        </div>
        <div className='justify-center mt-12'>
          <div className='flex flex-col flex-wrap sm:flex-row'>
            <div className='flex px-3 w-full flex-1 py-6'>
              <p>
                <h3 className='sm:text-base md:text-xl lg:text-3xl py-5 leading-tight'>
                  <Trans i18nKey='freeTrial.secondTitle' />
                </h3>
                <p className='mt-8 text-xl font-light'>
                  <Trans i18nKey='freeTrial.steps' /> :
                </p>
                <ol>
                  <li>
                    <Trans i18nKey='freeTrial.firstStep' />
                  </li>
                  <li>
                    <Trans i18nKey='freeTrial.secondStep' />
                  </li>
                  <li>
                    <Trans i18nKey='freeTrial.thirdStep' />
                  </li>
                  <li>
                    <Trans i18nKey='freeTrial.fourthStep' />
                  </li>
                  <li>
                    <Trans i18nKey='freeTrial.fifthStep' />
                  </li>
                  <li>
                    <Trans i18nKey='freeTrial.sixthStep' />
                  </li>
                  <li>
                    <Trans i18nKey='freeTrial.seventhStep' />
                  </li>
                </ol>
              </p>
            </div>
            <div className='px-3 w-full flex-1 hide-for-mobile'  >
              <FreeTrialForm
                handleCountryChange={handleCountryChange}
                pricingPackageState={pricingPackage}
                device="desktop"
              />
            </div>
          </div>
        </div>
      </section>

      <OurSolutions sectionId="solutions" />

      {/*
        <section id="demo" className="grey-background py-20">
        <div className="lg:mx-56 md:mx-16 mx-2 sm:mx-10 px-8 text-center">
          <h2 className="text-3xl lg:text-5xl font-semibold">
            See our Technology In Action
          </h2>
          <div className="youtube-container mt-12 ">
            <iframe
              title="DabaStore"
              src="https://www.youtube.com/embed/y1MavrDJD7Y?controls=0"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
      </section>
      */}

      <Features />

      <section id='pricing' className='grey-background'>
        <div className=' md:mx-24 mx-2 sm:mx-10 text-center'>
          <h2 className='text-3xl lg:text-5xl font-semibold'>
            <Trans i18nKey='pricing.mainTitle' />
          </h2>
          <h3 className='text-3xl py-5 font-semibold leading-tight'>
            <Trans i18nKey='pricing.secondTitle' />
          </h3>
          <Pricing
            handlePricingPackage={handlePricingPackage}
            country={country}
          />
        </div>
      </section>

      {/*<section id='company' className='py-20'>
        <div className=' md:mx-24 mx-2 sm:mx-10 px-8 text-center'>
          <h2 className='text-3xl lg:text-5xl font-semibold'>
            <Trans i18nKey='company.mainTitle' />
          </h2>
          <h3 className='sm:text-base md:text-xl lg:text-3xl py-5 leading-tight'>
            <Trans i18nKey='company.secondTitle' />
          </h3>
          <p className='mt-8 md:mt-12'>
            <AnchorLink className='px-4' href='#free-trial'>
              <Button className='lg:py-3 lg:px-8 py-2 px-4 bg-primary text-white'>
                <Trans i18nKey='openAnAccount' />
              </Button>
            </AnchorLink>

            <AnchorLink className='px-4' href='#contact'>
              <Button className='lg:py-3 lg:px-8 py-2 px-4 bg-primary text-white'>
                <Trans i18nKey='contactUs' />
              </Button>
            </AnchorLink>
          </p>
        </div>
      </section>*/}
      {/*
      <section id="testimonials" className="py-20">
        <div className=" lg:mx-56 md:mx-16 mx-2">
          <h2 className="text-3xl lg:text-5xl text-center font-semibold">
            What customers are saying about our products and services
          </h2>
          <div className="flex flex-col md:flex-row md:-mx-3 py-10">
            {customerData.map((customer) => (
              <div key={customer.customerName} className="flex-1 px-3">
                <CustomerCard customer={customer} />
              </div>
            ))}
          </div>
        </div>
      </section>
      */}

      <section id='faq' className='py-20 grey-background mb-8'>
        <div className=' md:mx-24 mx-2 sm:mx-10 text-center'>
          <h2 className='text-3xl lg:text-5xl font-semibold'>
            <Trans i18nKey='faq.mainTitle' />
          </h2>
          <h3 className='text-3xl py-5 font-semibold leading-tight'>
            <Trans>N'hésitez pas à</Trans>
            <AnchorLink className='blueLink' href='#contact'>
              &nbsp;
              <Trans i18nKey='contactUs' />
              &nbsp;
            </AnchorLink>
            <Trans>si vous avez d'autres questions</Trans>
          </h3>
          <Accordion hiddenTexts={accordionContent} />
        </div>
      </section>
      <section
        className=' md:mx-24 mx-2 sm:mx-10 py-20 bg-gray-200 rounded-lg text-center'
        id='contact'
      >
        <div id='contact_success'></div>
        <h3 className='text-5xl font-semibold'>
          <Trans i18nKey='contactUs' />
        </h3>
        <p className='mt-8 text-xl font-light'>
          <Trans i18nKey='contact.prompt' />
        </p>

        <div className='contact-1 py-4 md:py-6'>
          <div className='xl:w-10/12 xl:mx-auto px-4'>
            <div className='md:px-4'>
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
      {/*<CookieConsent
          debug={process.env.NODE_ENV!=='production'}
          cookieName="GoOnDemandCookieConsent"
      >
        We use cookies to ensure optimum experience for you on our website.
      </CookieConsent>*/}
    </Layout>
  );
};

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { ns: { in: ["index"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
